.NavBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: black;
}

a:hover {
    cursor: pointer;
}

.NavBar button {
    height: 20px;
}

.Links1 {
    width: 100px;
    display: flex;
    justify-content: space-between;
}

.Links2 {
    width: 400px;
    display: flex;
    justify-content: space-between;
}

img.logo {
    position: absolute;
    top: 10px;
    left: 10px;
    height: 22px;
    width: auto;
    z-index: 100;
}

a {
    text-decoration: none;
}

.links {
    position: absolute;
    width: 100%;
    z-index: 1000;
    top: 12px;
    right: 12px;
    color: white;
    text-transform: uppercase;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
    font-size: 9pt;
    z-index: 999999999999999999;
}

a.login {
    position: absolute;
    color: white;
    right: 2px;
    top: 0px;
    font-weight: 550;
    z-index: 100;
}

.full-navbar {
    background-color: black;
    position: absolute;
    height: 50px;
    top: 0;
    width: 100%;
}
a.logout {
    color: black;
    position: absolute;
    right: 0;
    margin-top: 12px;
    z-index: 10000;
  }

  .back-btn {
      position: fixed;
      top: 5px;
      left: 5px;
      z-index: 99999999999;
      font-size: 35px;
      color: rgba(78, 78, 78, 0.199);
      color: #e4ccc6a9;
      color: rgba(128, 128, 128, 0.733);
  }

  .hamburger {
      position: absolute;
      right: 10px;
      top: 0px;
      color: white;
      height: 50px;
      width: 50px;
      font-size: 18pt;
  }

  .hamburger a {
      display: block;
      position: absolute;
      top: 14px;
      right: 12px;
      font-size: 12pt;
      font-weight: 550;
      text-transform: uppercase;
      z-index: 99;
      color: #554c52;
  }

  .course-nav-p .hamburger a {
      color: #3a0a17;
  }

  .course-nav {
      
      position: fixed;
      top: 0;
      width: 100vw;
      height: 52px;
      z-index: 9999;
      margin-bottom: 10px;
  }

  .course-nav-p {
    background: linear-gradient(to left, white, #c282e2cc, black) ;
    background: linear-gradient( to right, #e72f6088 , #7b439791 );
    position: fixed;
    top: 0;
    width: 100%;
    height: 52px;
    z-index: 9999999999999999999999999;
}



 @-webkit-keyframes slide-in-right {
    0% {
      -webkit-transform: translateX(1000px);
              transform: translateX(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-right {
    0% {
      -webkit-transform: translateX(1000px);
              transform: translateX(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }

  @-webkit-keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .fade-in {
    -webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
            animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }
  
  

.slide-in-right {
	-webkit-animation: slide-in-right 0.3s cubic-bezier(0.445, 0.050, 0.550, 0.950) both;
	        animation: slide-in-right 0.3s cubic-bezier(0.445, 0.050, 0.550, 0.950) both;
}

.moving-gradient {
  background: linear-gradient(306deg, #7b4397, #e72f60);
  background-size: 300% 300%;

  -webkit-animation: WelcomePageGradient 10s ease infinite;
  animation: WelcomePageGradient 10s ease infinite;
}

.moving-gradient-2 {
  background: linear-gradient(306deg, #e72f60, #7b4397);
  background-size: 400% 400%;

  -webkit-animation: WelcomePageGradient 18s ease infinite;
  animation: WelcomePageGradient 18s ease infinite;
}

@-webkit-keyframes WelcomePageGradient {
  0%{background-position:0% 47%}
  50%{background-position:100% 54%}
  100%{background-position:0% 47%}
}
@keyframes WelcomePageGradient {
  0%{background-position:0% 47%}
  50%{background-position:100% 54%}
  100%{background-position:0% 47%}
}

@-webkit-keyframes WelcomePageGradient2 {
  0%{background-position:100% 54%}
  100%{background-position:0% 47%}
}
@keyframes WelcomePageGradient2 {
  0%{background-position:100% 54%}
  100%{background-position:0% 47%}
}

@-webkit-keyframes scale-in-ver-center {
    0% {
      -webkit-transform: scaleY(0);
              transform: scaleY(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      opacity: 1;
    }
  }
  @keyframes scale-in-ver-center {
    0% {
      -webkit-transform: scaleY(0);
              transform: scaleY(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      opacity: 1;
    }
  }
  

.scale-in-ver-center {
	-webkit-animation: scale-in-ver-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-ver-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.fade-in {
	-webkit-animation: fade-in 1.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in 1.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}


.slide-in-bottom {
	-webkit-animation: slide-in-bottom 0.2s both;
	        animation: slide-in-bottom 0.2s both;
padding-top: 40px;
        }


* {
    margin: 0;
    -webkit-font-smoothing: antialiased;
}

.shine {
  background: linear-gradient(273deg, #fcfcfc, #0e0d0d, #0e0d0d);
  background-size: 600% 600%;

  -webkit-animation: Shine 8s ease infinite;
  animation: Shine 8s ease infinite;
}

@-webkit-keyframes Shine {
  0%{background-position:0% 47%}
  50%{background-position:100% 54%}
  100%{background-position:0% 47%}
}
@keyframes Shine {
  0%{background-position:0% 47%}
  50%{background-position:100% 54%}
  100%{background-position:0% 47%}
}

*::-webkit-scrollbar {
  display: none;
}

* {
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: black;
}

.main {
background-color: black;
}

a {
    text-decoration: none;
    color: #2069e0;
}

a.assignment-submission {
  display: inline-block;
  text-decoration: none;
  border-bottom: 0;
  font-size: 10pt;
  background-color: #313131;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px;
  padding-left: 10px; padding-right: 10px;
  border-radius: 20px;
  color: #bbbbbb;
  text-transform: uppercase;
  font-size: 8pt;
  font-weight: 500;
  margin-top: 8px;
  margin-bottom: 5px;
}

.course-nav {
  border-bottom: .5px solid rgba(255, 255, 255, 0.451);

}

span.course-name-add {
  display: inline-block;
  position: absolute;
  right: 10px;
  color: white;
}

.main-page {
    position: relative;
    background-color: black;
    padding-top: 50px;
    margin: 0 auto;
    width: 600px;
    text-align: center;
    padding-bottom: 100px;
    min-height: 100vh;

}
.entry-text-o {
    position: relative;
    font-family: proxima-nova, sans-serif;
    font-size: 1.9em;
    z-index: 1000;
    font-weight: 550;
    line-height: 1.1;
    font-size: 40px;
    letter-spacing: 0em;
    padding-top: 30px;
    padding-bottom: 0px;
    margin-bottom: 40px;
    padding-left: 0;
    padding-right: 0;
    font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
    background: radial-gradient(ellipse at center, #faf1fd 10%, #000 100%);
    background-size: 50% 170%;
    background-position: center center;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-font-smoothing: antialiased;
  }

  .entry-text-p {
    position: relative;
    font-family: proxima-nova, sans-serif;
    font-size: 1.9em;
    z-index: 1000;
    font-weight: 550;
    line-height: 1.1;
    font-size: 40px;
    letter-spacing: 0em;
    padding-top: 30px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 0;
    font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
    background: radial-gradient(ellipse at center, #e72f60 10%, #7b4397 100%);
    background-size: 50% 170%;
    background-position: center center;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-font-smoothing: antialiased;
    
  }

  .get-started {
    position: relative;
    display: block;
    z-index: 1;
    width: 160px;
    height: 52px;
    margin: 20px auto;
    vertical-align: bottom;
    font-size: 40px;
    color: transparent;
    padding: 0px;
    text-align: center;
    text-transform: uppercase;
    font-size: 13px;
    -webkit-appearance: none;
    transition: .4s;
      border-image: linear-gradient(to right, #005e7f, #61b6cd);
      border-radius: 50px;
      background-size: 100%;
      background: black;
      border: 2px transparent;
      border-radius: 30px;
      background-origin: border-box;
      background-clip: content-box, border-box;
  }
  
  .get-started::after {
    z-index: -1;
    border-radius: 50px;
    color: white;
    content: '';
    display: block;    
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2.5px);
    height: calc(100% + 2px);
    background: linear-gradient(to right, #7b4397, #e72f60);
    background: linear-gradient(306deg, #e72f60, #7b4397);
    background-size: 200% 200%;
    -webkit-animation: WelcomePageGradient 4s ease infinite;
    animation: WelcomePageGradient 4s ease infinite;
  
    background-position: 0 var(--modal-button-gradient-pos);
    -webkit-transform: translateZ(-1px);
            transform: translateZ(-1px);
  }

  a.get-started {
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    
   }

   video.intro {

   }

   .section2 {
       margin-top: 40px;

   }

   video.intro {

   }

   .btn-text {
       color: white;
       background-color: transparent;
       position: absolute;
       top: 50%; left: 50%;
       width: 100%;
       -webkit-transform: translate(-50%, -50%);
               transform: translate(-50%, -50%);
   }

   .box-pink {
    box-shadow: #e72f601e 0px 40px 100px 40px;
  }

  h2 {
    color: #f5f5f7;
    display: block;
    font-size: 1.5em;
    -webkit-margin-before: 0.83em;
            margin-block-start: 0.83em;
    -webkit-margin-after: 0.83em;
            margin-block-end: 0.83em;
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
    -webkit-margin-end: 0px;
            margin-inline-end: 0px;
  }

  h2.headline {
    font-size: 28px;
    line-height: 1.125;
    font-weight: 700;
    letter-spacing: .004em;
    font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
  }

  img {
      width: 100%;
  }

  .schedule-inside {
    position: absolute;
    top: 5px;
    left: 50%;
    -webkit-transform: translate(-50%, 50%);
            transform: translate(-50%, 50%);
    width: 90%;
    margin: 0 auto;
    color: white;
    font-size: 9pt;
    font-weight: 550;
    background-color: transparent;
}

.schedule-btn {
    position: relative;
    display: block;
    width: 200px;
    padding: 20px;
    left: 50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
    margin: 0 auto;
    margin-top: -35px;
    vertical-align: middle;
    font-size: 40px;
    color: white;
    text-align: center;
    text-transform: uppercase;
    font-size: 13px;
    -webkit-appearance: none;
    transition: .4s;
      border-radius: 50px;
       border-radius: 30px;
      background-size: 100%;
      background: white;
      border: 2px transparent;
      background-origin: border-box;
      background-clip: content-box, border-box;
      z-index: 1;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  .schedule-btn::after {
    z-index: -1;
    border-radius: 50px;
    content: '';
    display: block;    
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2.5px);
    height: calc(100% + 2px);
    background: linear-gradient(to right, #7b4397, #e72f60);
    background-size: 100% 200%;
    background-position: 0 var(--modal-button-gradient-pos);
    -webkit-transform: translateZ(-1px);
            transform: translateZ(-1px);
}

img.course {
    padding-top: 0px;
    text-align: center;
    width: 100%;
    margin: 0;
    background: white;

}

.more-btn {
    color: #e72f60;
    padding: 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 8pt;
    text-transform: uppercase;
    font-weight: 700;
    background-color: white;
}

i {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    background-color: inherit;
    background: transparent;
}

.entry-text-low {
    display: block;
    position: relative;
    font-size: 17px;
    line-height: 1.47059;
    font-weight: 500;
    letter-spacing: -.022em;
    font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
    width: 90%;
    text-align: left;
    padding-top: 0px;
    padding-bottom: 0px;
    color: rgba(255, 255, 255);
    border-radius: 0;
    font-size: 40px;
    line-height: 1.15286;
    font-weight: 500;
    letter-spacing: .007em;
    font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
  }

  .cos-gradient {
    background: linear-gradient(to left, #7b4397, #e72f60);
    -webkit-background-clip: text;
    color: transparent;
    font-weight: 500;
    letter-spacing: .007em;
    font-size: 40px;
    padding-bottom: 10px;
    line-height: 1.15286;
    font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
   }

   .section3 {
       background-color: black;
       padding-top: 40px;
       padding: 0px;
       padding-bottom: 40px;
       padding-top: 40px;
   }

   .lower-text {
       color: #939393;
       line-height: 24px;
       font-weight: 600;
       font-size: 18px;
       font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
       text-align: left;
       padding-top: 20px;
       padding-bottom: 40px;
   }

   .l {
       text-align: left;
       margin: 0;
       padding: 0;
       background-color: black;
   }

   .main-body {
       position: fixed;
       top: 0;
       background-color: black;
       background-color: #0e0205;
       background-color: black;
       width: 100%;

       text-align: center;
   }

   .col {
       width: 25%;
   }

   .section4 {
    background-color: #f7f7f7;
    width: 100%;
    text-align: center;
    overflow: hidden;
}

.course-container {
  width: 100%;
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
    background-color: white;
    text-align: center;
}

.PMU-details {
  min-height: 100vh;
  padding-bottom: 70px;
  background-color: black;
}

.pmu-detail-page {
  padding-bottom: 0px;
}

.PMU-details-welcome {

}

.padding {
  height: 16vh;
  background-color: transparent;
  width: 100%;
}

.course-wrap {
  
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.footer {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 7.5pt;
    text-align: center;
    color: rgba(43, 43, 43, 0.616);
    font-weight: 400;
    padding: 20px;
    background-color: #f7f7f7;
}

.drop {
  position: relative;
   background-color: #f7f7f7;
    text-align: left;
    font-size: 9pt;
    padding: 10px;
    padding-left: 0;
    color: rgb(43, 43, 43);
    border-bottom: 1px solid rgba(128, 128, 128, 0.219);
}


span.add {
    position: absolute;
    color: rgb(43, 43, 43);
    font-size: 9pt;
    right: 10px;
    background-color: #f7f7f7;
}

/* Login Form */
.login-form-container {
    margin-top: 40px;
    text-align: center;
    background-color: black;
}

.login-form {
    margin: 0 auto;
}

.login-form input {
    outline: none;
    background-color: #272727;
    width: 50%;
    padding: 10px;
    margin-top: 40px;
    border: none;
    border-bottom: 1px solid transparent;
    box-shadow: none;
    margin-bottom: 10px;
    font-size: 16px;
}

input:focus {
    color: white;
    border: 1px solid #e72f60;
    font-weight: 500;
    font-size: 16px;
}

.tweets {
  margin-top: 50px;
  position: absolute;
}

.login-title {
  font-size: 11pt;
  text-transform: uppercase;
}



/* Dashboard */
.dashboard {
  background-color: white;
  margin: 0px;
  margin-top: -10px;
}

/* LOGIN FORM */

/* LOGIN FORM */

.nav-btn {
  color: #f5f5f7;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 450;
  text-align: left;
  text-transform: uppercase;
  padding: 20px;
  padding-top: 12px;
  font-size: 14px;
  position: fixed;
  left: 0;
  top: 0;
  width: 40%;
  z-index: 9999999999999999999999;
  background-color: transparent;
}

.nav-btn i {
  font-size: 16px;
}

.black {
  color: #0F0F0F;
}

.login-btn {
  position: fixed;
  right: 0px;
  top: 12px;
  text-align: right;
  color: white;
  font-size: 9pt;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 450;
  text-transform: uppercase;
  padding-left: 15px; padding-right: 15px;
  z-index: 999999999999;
  width: 40%;
  height: 50px;
  background-color: transparent;
}

.overlay {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.ring {
  position: absolute;
  top: 50%; left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: transparent;
}

.login-btn i{
  font-size: 16pt;
  padding: 4px;
  color: rgba(128, 128, 128, 0.733);
}

h4 {
  color: #f5f5f7;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 23px;
  font-weight: lighter;
  padding-bottom: 30px;
}

h3 {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #f5f5f7;
  font-weight: 500;
  font-size: 1.8em;
  text-align: left;
  line-height: 1.2em;
  padding-bottom: 20px;
  text-transform: uppercase;
}

.login-form {
  display: block;
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

label {
  display: block;
  color: rgba(255, 255, 255, 0.685);
  text-align: left;
  width: 80%;
  margin: 5px auto;
  margin-left: 6.5%;
  text-transform: uppercase;
  font-size: 9pt;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

  input {
    display: block;
    top: 0px;
    position: relative;
    width: 82%;
    color: white;
    background-color: #161618;
    background-color: #1c1c1e;
    background-color: #272727;
    margin: 0 auto;
    border: none;
    height: 28px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    padding: 8px;
    padding-left: 15px;
    border-radius: 0px;
    border-bottom: 1px solid transparent;
    line-height: 1.2em;
  }

  input:-webkit-autofill, input:-webkit-autofill:focus {
    background: rgb(18, 18, 18);
    color: white;

  }

  .error {
    display: block;
    padding: 5px;
    color: #e72f60;
    font-size: 9pt;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
    text-align: center;
  }
  
  textarea {
    display: block;
    background-color: #161618;
    background-color: #272727;
    position: relative;
    color: white;
    top: 0px;
    height: 100px;
    width: 82%;
    font-size: 16px;
    font-weight: 500;
    padding: 8px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    border-radius: 0px;
    margin: 0 auto;
    margin-bottom: 10px;
    border: none;
  }


  textarea::-webkit-input-placeholder {
    color: #73737a;
    font-weight: 500;
    font-size: 16px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }


  textarea::placeholder {
    color: #73737a;
    font-weight: 500;
    font-size: 16px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  textarea:focus {
    outline: none;
    border: none;
    background-color: #272727;
  }

  input::-webkit-input-placeholder {
    color: #73737a;
    font-weight: 500;
  }

  input::placeholder {
    color: #73737a;
    font-weight: 500;
  }


  input:focus {
    border: none;
    outline: none;
    border: 1px solid #e72f60;
    border-radius: 0px;
    background-color: #161618;
    background-color: #272727;
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  input:after {
    border: none;
    outline: none;
    border-bottom: 1px solid rgb(29, 29, 29);
    background-color: rgba(29, 29, 29, 0.747);
  }

  input.submit {
    position: relative;
    display: block;
    color: white;
    top: 20px;
    padding-bottom: 40px;
    padding-top: 20px;
    margin-top: 0px;
    text-transform: uppercase;
    font-size: 13px;
    -webkit-appearance: none;
    transition: .4s cubic-bezier(0.250, 0.460, 0.450, 0.940);
    width: 85%;
    border: 0px solid transparent;
    background-clip: border-box;
    border-radius: 14px;
    background-image: linear-gradient(to left, #7b4397, #e72f60);
  }

  input.searchbar {
    padding: 5px;
    padding-left: 10px;
  }

  .submit:hover {
    cursor: pointer;
  }

  input:-webkit-autofill {
    -webkit-text-fill-color: #161618f1 !important;
}

  input.next {
    position: relative;
    color: white;
    padding-bottom: 40px;
    padding-top: 20px;
    margin-top: 0px;
    text-transform: uppercase;
    font-size: 13px;
    -webkit-appearance: none;
    transition: .4s cubic-bezier(0.250, 0.460, 0.450, 0.940);
    width: 90%;
    border-radius: 50px;
    border: 0px solid transparent;
    background-clip: border-box;
    background-image: linear-gradient(to left, #7b4397, #e72f60);
  }

  input.inside5 {
    outline: 1px solid #e72f60;
    color: white;
    font-size: 10.5pt;
    line-height: 1.3;
    font-weight: 550;
    margin: 0;
    background-color: transparent;
    width: 70px;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  input.next-no {
    display: block;
    position: relative;
    color: white;
    padding-bottom: 40px;
    padding-top: 20px;
    margin-top: 0px;
    text-transform: uppercase;
    font-size: 13px;
    -webkit-appearance: none;
    transition: .4s;
    width: 90%;
    border-radius: 50px;
    border: 0px solid transparent;
    background-clip: padding-box;
    background: linear-gradient(to top right, #f17C35, #e72f60);
    margin-top: 5px;
    top: 5px;
    -webkit-filter: blur(3px);
            filter: blur(3px)
  }

  input.submit-no {
    display: block;
    position: relative;
    color: white;
    padding-bottom: 40px;
    padding-top: 20px;
    margin-top: 0px;
    text-transform: uppercase;
    font-size: 13px;
    -webkit-appearance: none;
    transition: .4s;
    width: 85%;
    border: 0px solid transparent;
    background-clip: padding-box;
    background-image: linear-gradient(to left, #7b4397, #e72f60);
    margin-top: 0px;
    top: 20px;
    -webkit-filter: blur(3px);
            filter: blur(3px);
    z-index: 100;
    border-radius: 14px;
  }

  input[type="file"] {
    display: none;
  }

  input[type="file"] i{
    color: #b3b3b3;
    text-align: center;
  }

  .add-input {
    position: relative;
    text-align: center;
    width: 100%;
    height: 100%;
  }

  .add-input i {
    color: #b3b3b3;
    position: absolute;
    font-size: 12pt;
    top: 50%; left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }

.custom-file-upload-edit {
  position: absolute;
  margin-top: 0;
  padding-left: 0;
  background-color: #3333339d;
  color: #e72f60;
  width: 88%;
  height: 105px;
  z-index: 9999;
}

.custom-file-upload-edit i {
  color: white;
  font-size: 14pt;
  padding: 42px;
}

.ring {
  position: absolute;
  top: 50%; left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

  .disclaimer {
    display: block;
    position: relative;
    color: #f5f5f7;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 300;
    width: 90%;
    font-size: 12px;
    padding: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin: 0 auto;
    line-height: 1.6em;
    margin-top: 20px;
    padding-bottom: 0px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    text-align: center;
  }

  .connecting {
    display: none;
    z-index: 2000000000;
    color: #cd9c11;
  }

  .entry-image-holder {
    position: relative;
    height: 100%;
  }

  /* Sean's Face */
 img.face {
  position: relative;
  display: inline-block;
  height: auto;
  margin: 0px;
  margin-top: 5px;
  width: 12%;
  border-radius: 50%;
  z-index: 100;
  text-align: left;
  vertical-align: top;
}

img.face-2 {
 display: inline-block;
 width: 40px;
 border-radius: 50%;
}

.body {
  margin-top: 24px;
}

.ai-talk {
  position: relative;
  vertical-align: top;
  width: 88%;
  padding: 0px;
  margin: 0 auto;
  color: rgba(99, 99, 102, 0.945);
  font-weight: 600;
  text-align: left;
  z-index: 0;
  border-radius: 0px;
  letter-spacing: .002em;
  font-size: 20px;
  padding-top: 30px;
  padding-bottom: 20px;
  background-color: transparent;
  font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
}

.ai-talk-2 {
  position: relative;
  z-index: 9999;
  margin: 0 auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  transition: ease-in-out .6s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.login-form-container {

}

.ai-text {
  display: block;
  vertical-align: bottom;
  padding: 5px;
}

.ai-bubble {
  position: relative;
  bottom: 20px;
  margin: 10px;
  color: #f5f5f7;
}

.ai-button-holder {
  display: block;
  position: relative;
  margin-top: 140px;
  text-align: center;
}

.ai-btn {
 font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
 font-size: 14px;
 background-color: transparent;
 color: #b3b3b3f3;
 font-weight: 700;
 padding: 20px;
 margin: 0 auto;
 margin-top: 10px;
 display: block;
 width: 100%;

}

.ai-btn i {
 font-size: inherit;
 color: inherit;
}

button {
  outline: none;
  border: none;
}

.ai-gradient {
  background-image: linear-gradient(to left, #7b4397, #e72f60);
  -webkit-background-clip: text;
  color: transparent;
  font-weight: 600;
  line-height: 1.2em;
  letter-spacing: .007em;
  font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
 }

 .ai-text-bx {
  display: inline-block;
  text-align: left;
  padding-left: 16px;
  padding-right: 0px;
  margin-left: 0px;
  max-width: 75%;
  background-color: transparent;
  vertical-align: top;
  color: white;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.2em;
  letter-spacing: .007em;
  font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;

}

.full-body {
  z-index: 99999;
  background-color: rgb(18, 18, 18);
  background-color: black;
  height: 120vh;
  overflow-y: scroll;
  top: 0;
}

.hidden {
  display: none;
  transition: .4s;
}



.pdfViewer {
  display: block;
  width: 100%;
  position: relative;
  transition: .4s;
}


.dark-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.233);
}

.course-container2 {
  position: fixed;
  opacity: 1;
  width: 100%;
  margin: 0 auto;
  height: 100vh;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  z-index: 100;
  overflow-y: scroll;
}

.course-container-inside {
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
  position: absolute;
  bottom: 0%;
  height: 74%;
  background-color: white;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  z-index: 1000000000000000000;
}

.course-section {
  max-width: 100%;
  width: 200px;
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.course-header {
  background-color: #310914;
  background-image: linear-gradient(to bottom left, #7b4397, #e72f60);
  padding: 18px;
  margin-bottom: 5px;
  color: white;
  font-size: 10pt;
  font-weight: 700;
  width: 90%;
  box-shadow: #38212733 0px 2px 8px;
}


.course-header i {
  position: relative;
  float: right;
  color: white;
  background-color: transparent;
  background: transparent;
}

.course-detail {
  height: auto;
  transition: height 3s ease;
}

.course-check {
  background-color: rgba(128, 128, 128, 0.164);
  background-color: #272324;
  display: inline-block;
  text-align: center;
  padding: 14px;
  padding-right: 0;
  padding-left: 0;
  width: 10%;
  color: white;

  font-size: 11pt;
  font-weight: 500;
}

.course-page {
  background-color: rgba(128, 128, 128, 0.164);
  background-color: #272324;
  width: 84%;
  display: inline-block;
  float: right;
  padding: 14px;
  color: white;
  font-size: 11pt;
  font-weight: 500;
  padding-left: 5%;
  padding-right: 0;
  border-left: 1% solid rgba(128, 128, 128, 0.575);
}

.course-page i {
  padding-right: 5px;
}

img.header {
  display: none;
  margin-top: 0px;
  width: 100%;
  position: fixed;
  top: 50px;
  left: 50%;
  z-index: -2;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
}

.blk {
  color: black;
}

.pdf-container {
  display: block;
  position: relative;
  text-align: center;
}

.document {

}

.page {


}

.check-mark {
  position: fixed;
  top: 3px;
  right: 5px;
  z-index: 999999999999999;
  font-size: 40px;
  color: rgba(128, 128, 128, 0.733);
}

.dashboard i {
  color: rgba(0, 0, 0, 0.623);
}

.dash-nav {
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
  background-color: white;
}

.green i{
  color: #e72f60;
}

.selected {
  color: #e72f60;
}



.scroll-tab {
  position: absolute;
  width: 20%;
  height: 4px;
  border-radius: 50px;
  top: -10px;
  left: 50%;
  font-size: 8pt;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  background-color: rgba(0, 0, 0, 0.24);
}

.scroll-tab i {
  background-color: black;
}

.top {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;

}

.course-wrap {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.section-footer {
  padding: 10px;
  color: gray;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  text-align: right;
}

.section-title {
  color: white;
  font-size: 9.5pt;
  text-transform: uppercase;
}

video.course {
  margin-bottom: 20px;
}

.check {
  position: relative;
  float: right;
  color: gray;
  padding-left: 10px;
  font-size: 14pt;
}

.check i {
  color: gray;
  font-size: 16pt;
}

.pink, .pink i {
  color: #e72f60;
}

.submission {
  position: absolute;
  width: 90%;
  height: 80%;
  top: 50%; left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 50px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

a.download-link {
  position: absolute;
  top: 170px;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  color: #7b4397;
  font-weight: 500;
  border: 1px solid #7b4397;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 50px;
}

.submit-link {
  position: absolute;
  top: 240px;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  color: #7b4397;
  font-weight: 500;
  border: 1px solid #7b4397;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 50px;
}

* {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.submission-instructions {
  position: absolute;
  top: 50px;
  left: 50%; -webkit-transform: translate(-50%); transform: translate(-50%);
  text-align: left;
  padding: 0px;
  color: rgba(54, 54, 54, 0.89);
  font-size: 11pt;
  font-weight: 500;
  width: 80%;
}

.close i {
  font-size: 25px;
  color: #7b4397;
  position: absolute;
  right: 25px;
  top: 20px;
}

.add-input {
  position: relative;
  text-align: center;
  width: 100%;
  height: 100%;
}

.add-input i {
  color: #272727;
  position: absolute;
  font-size: 12pt;
  top: 50%; left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.custom-file-upload {
  display: block;
  position: absolute;
  top: 150px;
  height: 100px;
  width: 88%;
  overflow: hidden;
  background-color: #7b439757;
  color: #272727;
  border-radius: 20px;
  margin-bottom: 10px;
}

img.submitted-image {
  position: absolute;
  top: 150px;
  max-height: 200px;
  width: auto;
  max-width: 100%;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
}

.submission-accepted {
  position: absolute;
  top: 50px;
  left: 50%; -webkit-transform: translate(-50%); transform: translate(-50%);
  text-align: left;
  padding: 0px;
  color: #e72f60;
  font-size: 11pt;
  font-weight: 500;
  width: 80%;
}

.submit-cancel {
  position: absolute;
  bottom: 50px;
  display: inline-block;
  width: 30%;
  text-align: center;
  left: 12%;
  font-weight: 500;
  font-size: 11pt;
  color: #27272781;
  padding: 10px;
  background-color: rgba(128, 128, 128, 0.116);
  border-radius: 50px;
  border: 1px solid transparent;
}

.submit-ok {
  position: absolute;
  bottom: 50px;
  display: inline-block;
  width: 30%;
  text-align: center;
  right: 12%;
  font-weight: 500;
  font-size: 11pt;
  color: #e72f60;
  border: 1px solid #e72f60;
  padding: 10px;
  border-radius: 50px;
}

.pink-back {
  background-color: #e72f6091;
  color: white;
}

.pink-back i {
  color: white;
}

/* Checkout Form Container */

.checkout-form-container {
  position: absolute;
  top: 0;
  background-color: purple;
  height: 500px;
  width: 100%;
  z-index: 100000;
}

.section {
  width: 100%;

}

.section-2 {
  width: 100%;

}


video.intro {
  width: 100%;
}

.trans-90 {
  color: white;
}

.wht {
  color: white;
  background-color: transparent;
}

.dash-main {
  position: relative;
  top: 0;
  padding: 10px;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  background-color: #0e0205;
  background-color: #1e1025;
  text-align: center;
  padding-bottom: 40px;
}

.dash {
  padding: 20px;
  padding-top: 50px;
  width: 600px;
  margin: 0 auto;
  text-align: justify;
  position: relative;

}

.course-list-item {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  width: 95%;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid #272727;
  border-bottom: 1px solid #272727;
}

.course-list-item i {
  position: relative;
  float: right;
}

.inside {
  position: fixed;
  text-align: center;
  width: 40px;
  height: 40px;
  background-color: #e72f60;
  border-radius: 50%;
  color: white;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  padding: 10px;
}

.inside-scroll {
  overflow-y: scroll;
  height: 80vh;
}

.register {
  position: relative;
  text-align: left;
  color: #e72f60;
  width: 100%;
  padding: 0px;
  padding-top: 0px;
}

.register i {
  padding-left: 2px;

}

.dash-2 {
  background-color: white;
  top: 0;
}

* {
  background-color: transparent;
}

.course-order-header {
  padding-left: 0px;
  padding-top: 20px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.795);
  font-size: 8.5pt;
  padding-bottom: 0px;
  font-weight: 500;
}

.course-name {
  padding-left: 0px;
  padding-top: 5px;
  color: white;
  font-size: 10pt;
  line-height: 1.4em;
  

}

.course-cost {
  padding-left: 0px;
  padding-top: 5px;
  color: white;
  font-size: 10pt;
  line-height: 1.4em;
}

.card-element {
  margin: 10px;
  margin-left: 0;
  margin-right: 0;
  padding: 5px;
  background-color: #272727;
}

.card-element::-webkit-input-placeholder {
  color: #161618;
  text-transform: uppercase;
}

.card-element::placeholder {
  color: #161618;
  text-transform: uppercase;
}

.light {
  color: #797979;
  transition: .4s;
}


.schedule-open {
  position: fixed;
  top: 0;
  bottom: 0;
  background: linear-gradient(to bottom right, #7b4397, #e72f60);
  height: 100vh;
  overflow-y: hidden;
  width: 100%;
  text-align: center;
  z-index: 999999999999999999999999999999;
}

.schedule-close {
  position: absolute;
  top: 20px;
  font-size: 11pt;
  padding: 5px;
  text-transform: uppercase;
  color: white;
  left: 20px;
  text-align: left;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  width: 100%;
  background-color: transparent;
}

iframe.schedule {
  position: relative;
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  width: 100%;
  height: 85vh;
  background-color: transparent;
  scrollbar-width: 0px;
  scrollbar-color: transparent;
  -webkit-scrollbar-color: transparent;
}

.schedule-close:hover {
  cursor: pointer;
}

.schedule-close i {
  font-size: 11pt;
  font-weight: inherit;
}

.schedule-inside-2 {
  position: absolute;
  top: 70px;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  width: 600px;
  margin: 0 auto;
  box-shadow: 0 -2px 20px rgba(0, 0, 0, 0.233);
}
.c {
  text-align: center;
  margin: 0 auto;
}

.l {
  text-align: left;
}

.main-page-2 {
  width: 600px;
  margin: 0 auto;
  padding-top: 60px;
}


.task-title {
  margin-left: 0px;
  margin-bottom: 10px;
  font-size: 26px;
}

.client-page {
  position: fixed;
  padding: 20px;
  margin-top: 50px;
  top: 0;
  right: 0;
  width: 600px;
  background: rgb(27, 27, 27);
  height: 600px;
  overflow: scroll;
  z-index: 9999999999999999999999999999999999999999999999999;
}

.task-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 20%;
  min-width: 380px;
  top: 0px;
    padding-top: 60px;
    height: 100vh;
    overflow-y: scroll;
    z-index: 999999999;
    background-color: rgba(0, 0, 0, 0.712);
    -webkit-backdrop-filter: blur(7px);
            backdrop-filter: blur(7px);
    z-index: 99999999999999999999999;

}


@media print {
  .pdf-container {
    display: none;
  }

  html, body {
    display: none;  /* hide whole page */
 }
}
a {
  background-color: transparent;
}

.landing-page {
  background-color: black;
  min-height: 100vh;
  top: 0;
  position: relative;
}

.disclosure-text {
  position: relative;
  font-size: 14px;
  width: 90%;
  padding-top: 5px;
  margin: 10px auto;
  line-height: normal;
  color: #fdfdfd;
}

.disclosure-text a {
 color: white;
}

.moving-gradient {
  background: linear-gradient(306deg, #7b4397, #e72f60);
  background-size: 300% 300%;

  -webkit-animation: WelcomePageGradient 10s ease infinite;
  animation: WelcomePageGradient 10s ease infinite;
}

.get-started2 {
  position: absolute;
  display: block;
  top: 45.5%;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  width: 185px;
  height: 48px;
  margin: 20px auto;
  vertical-align: middle;
  font-size: 45px;
  color: white;
  padding: 0px;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  -webkit-appearance: none;
  transition: .4s;
  border-image: linear-gradient(to right, #005e7f, #61b6cd);
  background: linear-gradient(to right, #7b4397, #e72f60);
  border-radius: 50px;
  background-size: 100%;
  border: 2px transparent;
  background: black;
  background-color: black;
  border-radius: 30px;
  background-origin: border-box;
  background-clip: content-box, border-box;
  
}

.get-started2::after {
  z-index: -1;
  border-radius: 50px;
  content: '';
  display: block;    
  position: absolute;
  top: -1px;
  left: -1px;
  width: calc(100% + 2.5px);
  height: calc(100% + 2px);
  background: linear-gradient(#61b6cd 35%, #005e7f 45%);
  background: linear-gradient(to right, #7b4397, #e72f60);
  background-color: black;
  background: black;
  background-size: 100% 200%;
  background-position: 0 var(--modal-button-gradient-pos);
  -webkit-transform: translateZ(-1px);
          transform: translateZ(-1px);
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 1);
  -webkit-animation: WelcomePageGradient 4s ease infinite;
  animation: WelcomePageGradient 4s ease infinite;
}

.button-background {
  position: absolute;
  display: block;
  width: 188px;
  left: 51%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  top: 35.3%;
  height: 54px;
  margin: 20px auto;
  vertical-align: middle;
  font-size: 45px;
  color: white;
  padding: 0px;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  -webkit-appearance: none;
  transition: .4s;
  border-image: linear-gradient(to right, #005e7f, #61b6cd);
  background: linear-gradient(to right, #7b4397, #e72f60);
  background: black;
  border-radius: 50px;
  background-size: 100%;
  background: rgb(0, 0, 0);
  border: 2px transparent;
  border-radius: 30px;
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.button-background::after {
  z-index: 1;
  border-radius: 50px;
  content: '';
  display: block;    
  position: absolute;
  top: -1px;
  left: -1px;
  width: calc(100% + 2.5px);
  height: calc(100% + 2px);
  background: linear-gradient(#61b6cd 35%, #005e7f 45%);
  background: linear-gradient(to right, #7b4397, #e72f60);
  background-size: 100% 200%;
  background-position: 0 var(--modal-button-gradient-pos);
  -webkit-transform: translateZ(-1px);
          transform: translateZ(-1px);
}

.logo-main {
  position: relative;
  width: auto;
  height: 45px;
}

span.btn-text {
  position: absolute;
  width: 100%;
  left: 0;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  font-weight: 500;
  font-size: 16.5px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: white;
  background-color: transparent;
}

.down-60 {
  position: absolute;
  top: 25%;
  left: 50%;
  width: 100%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
}

.dot {
  color: white;
}

.nodot {
  color:rgb(84, 84, 84);
}

.front-desk {
  width: 100%;
  margin-top: 0px;
  padding-top: 55px;
  background: black;
  position: fixed;
  height: 100vh; overflow: scroll;
  white-space: nowrap;

}

.front-desk-full {
  margin-top: 50px;
  padding-top: 10px;
}

.left-half {
  width: 20%;
  min-width: 380px;
  height: 100%;
  min-height: 100vh;
  display: inline-block;
  position: relative;
  z-index: 999;
  vertical-align: top;
  overflow: hidden;
  background: black;
  overflow: scroll;
  box-shadow: rgba(255, 255, 255, 0.114) 1.95px 1.95px 2.6px;
  border-right: .5px solid white;
}

.right-half {
  display: inline-block;
  width: auto;
  max-width: 78%;
  margin-left: .5%;
  text-align: center;
  position: relative;
  vertical-align: top;
  overflow: hidden;
  overflow-x: scroll;
  overflow-y: scroll;
  padding-top: 0px;
  white-space: nowrap;
}

.inside-right-half {
  position: relative;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  overflow: scroll;
 
}

.inside-right-half-full {
  position: relative;
  margin: 0 auto;
  text-align: center;
  overflow: scroll;
}

.abs-list td.calendar-month i {
  background: transparent;
  margin-top: -7px;
  vertical-align: middle;
  font-size: 16px;
  
}



.abs-list-2 td.calendar-month-2 i {
  background: transparent;
  margin-top: -7px;
  vertical-align: middle;
  font-size: 16px;
}

.chevron-left-close {
  position: absolute;
  top: 0; left: 0;
  background: linear-gradient(306deg, #7b4397, #e72f60);
  background-size: 300% 300%;

  -webkit-animation: WelcomePageGradient 10s ease infinite;
  animation: WelcomePageGradient 10s ease infinite;
  width: 50px;
  height: 50px;
  width: 0;
  height: 0;
  border-top: 50px solid #7b4397;
  border-top-left-radius: 8px;
  border-right: 50px solid transparent;
  margin: 2px;
 
}

.chevron-left-open {
  position: absolute;
  top: 0; left: 0;
  background: linear-gradient(306deg, #7b4397, #e72f60);
  background-size: 300% 300%;

  -webkit-animation: WelcomePageGradient 10s ease infinite;
  animation: WelcomePageGradient 10s ease infinite;
  width: 50px;
  height: 50px;
  width: 0;
  height: 0;
  border-bottom: 50px solid #7b4397;
  border-bottom-right-radius: 8px;
  border-left: 50px solid transparent;
  margin: 0px;
 
}

.chevron-left-close, .chevron-left-open:hover {
  cursor: pointer;
}

.right-half, .right-half-full i:hover {
  cursor: pointer;
}

.right-half i {
  position: absolute;
  color: white;
  font-size: 16px;
  z-index: 99;
  left: 8px;
  top: 8px;
  box-shadow: rgba(255, 255, 255, 0.114) 1.95px 1.95px 2.6px;
}

.right-half-full {
  display: inline-block;
  width: 100%;
  margin-left: 0px;
  position: relative;
  vertical-align: top;
  overflow: hidden;
  overflow: scroll;
  padding-top: 0px;
  margin: 0 auto;
}

.right-half-full i {
  position: absolute;
  color: white;
  font-size: 16px;
  z-index: 999999;
  left: 28px;
  top: 27px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  box-shadow: rgba(255, 255, 255, 0.114) -1.95px -1.95px -2.6px;
}

.add-new-form {
  width: 500px;
}

@media (max-width: 800px) {

  .down-60 {
    position: absolute;
    top: 10%;
    left: 50%;
    width: 100%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
  }

  .logo-main {
    position: relative;
  
    width: 90%;
    height: auto;
  }

  .main-page {
    width: 100%;
  }

  .main-page-2 {
    width: 90%;
    margin: 0 auto;
    padding-top: 60px;
  }

  a {
    background-color: transparent;
  }

  iframe.schedule {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    width: 100%;
    height: 75vh;
    background-color: transparent;
    scrollbar-width: 0px;
    scrollbar-color: transparent;
    -webkit-scrollbar-color: transparent;
  }

  .schedule-btn {
    position: relative;
    display: block;
    width: 80%;
    left: unset;
    -webkit-transform: unset;
            transform: unset;
    padding: 20px;
    margin: 0 auto;
    margin-top: -25px;
    vertical-align: middle;
    font-size: 40px;
    color: white;
    text-align: center;
    text-transform: uppercase;
    font-size: 13px;
    -webkit-appearance: none;
    transition: .4s;
      border-radius: 50px;
       border-radius: 30px;
      background-size: 100%;
      background: white;
      border: 2px transparent;
      background-origin: border-box;
      background-clip: content-box, border-box;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  .section {
    width: 100%;
    display: block;
    height: 100vh;
  }

  .section-2 {
    width: 100%;
    display: block;
  }

  .section4 {
    background-color: #f7f7f7;
    width: 100%;
    text-align: center;
    overflow: hidden;
}


.course-container {
  display: block;
  width: 100%;
  margin: 0 auto;
    margin-bottom: 20px;
    background-color: white;
}


  video.intro {
    width: 100%;
    height: 100%;
    z-index: 10000;
    padding-top: 20px;
    -webkit-animation: fade-in 1.7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in 1.7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

  }

  .entry-text-o {
    position: relative;
    font-family: proxima-nova, sans-serif;
    font-size: 1.9em;
    z-index: 1000;
    font-weight: 550;
    line-height: 1.1;
    font-size: 32px;
    letter-spacing: 0em;
    padding-top: 30px;
    margin-bottom: 40px;
    padding-left: 0;
    padding-right: 0;
    font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
    background: radial-gradient(ellipse at center, #faf1fd 10%, #000 100%);
    background-size: 100% 200%;
    transition: 1s cubic-bezier(0.86, 0, 0.07, 1);
    background-position: center center;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-font-smoothing: antialiased;
  }

  .blk-pnk {
    background: #0e0205;
  }

  .schedule-inside-2 {
    position: absolute;
    top: 70px;
    left: 50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
    width: 90%;
    margin: 0 auto;
    box-shadow: 0 -2px 20px rgba(0, 0, 0, 0.233);
  }

  .section3 {
    background-color: black;
    padding-top: 40px;
    padding: 30px;
    padding-bottom: 40px;

}

img.course {
  position: relative;
  padding-top: 0px;
  text-align: left;
  width: 100%;
  margin: 0;
  background: white;
}

img.course-2 {
  position: relative;
  padding-top: 0px;
  text-align: left;
  width: 100%;
  margin: 0;
}

a {
  background-color: transparent;
}

.pdf {
  display: block;
  position: relative;
  top: 0;
  width: 100%;
  margin: 0 auto;
  height: 80%;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  z-index: 99999999999999999999;
  color: black;
}

.landing-page {
  background-color: black;
  min-height: 100vh;
  top: 0;
  position: relative;
}

.down-60 {
  position: absolute;
  top: 20%;
  left: 50%;
  width: 100%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
}



}


/* ----------------------------------------------
 * Generated by Animista on 2021-5-25 11:21:13
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(1000px);
              transform: translateY(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(1000px);
              transform: translateY(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }

  @-webkit-keyframes slide-out-bottom {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(1000px);
              transform: translateY(1000px);
      opacity: 0;
    }
  }
  @keyframes slide-out-bottom {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(1000px);
              transform: translateY(1000px);
      opacity: 0;
    }
  }

  .slide-out-bottom {
	-webkit-animation: slide-out-bottom 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: slide-out-bottom 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

  .slide-in-bottom {
	-webkit-animation: slide-in-bottom 0.5s ;
	        animation: slide-in-bottom 0.5s ;
}

.course-info {
    padding-top: 20px;
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    line-height: 1.4;
    font-size: 10pt;
    font-weight: 500;
}

.course-logo {
    height: 22px;
    width: auto;
    position: absolute;
    top: 4px;
    left: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
 
}

.course-main {
    width: 100%;
    padding: 0;
    margin: 0 auto;
    background-color: rgb(0, 0, 0);
    overflow: hidden;
    padding-bottom: 200px;
}

.fifty {
    display: inline-block;
    margin: 0 auto;
    width: 47%;
    height: 200px;
    text-align: center;
    color: white;
}

.course-section {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0 auto;
    padding: 0px;
    width: 92%;
    height: 90%;
    border-radius: 20px;
    opacity: 1;
    background-color: rgb(0, 0, 0);
    box-shadow: rgba(88, 57, 102, 0.2) 0px 2px 8px 0px;

}

.course-back-image {
    width: auto;
    height: 120%;
    position: relative;
    top: 50%; left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 0;
    opacity: .6;
    z-index: 0;
    border-radius: 20px;
}

.course-section:after {
    position: absolute;
    pointer-events: none;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 20px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, .2);
    background-image: linear-gradient(-45deg, rgba(24, 23, 23, 0.514), rgba(255, 255, 255, 0.308) 50%, rgba(255, 255, 255, 0.082) 50%);
    -webkit-mask-image: linear-gradient(#000, transparent);
}


.course-section-title {
    position: absolute;
    z-index: 100;
    text-transform: uppercase;
    text-align: left;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-size: 11pt;
    font-weight: 900;
    opacity: 1;
    color: #ffffff;
    font-family: 'Roboto Condensed', sans-serif;

}

.p-20 {
    padding-bottom: 20px;
    padding-top: 10px;
    font-size: 18pt;
    font-weight: 350;
    background-image: radial-gradient(to center, white, black);
    -webkit-background-clip: text;
    text-align: left;
}

.p-10 {
  padding: 20px;
  padding-top: 0;
  padding-bottom: 10px;
  line-height: 1.4em;
  margin-bottom: 20px;
}

.open-section {
    position: fixed;
    width: 100%;
    left: 0;
    z-index: 100000;
    top: 7%;
    background-color: #f0f0f0;
    color: black;
    height: 90%;
    font-size: 11pt;
    font-weight: 300;
    overflow-y: scroll;
    padding-bottom: 100px;
    transition: .6s;
}

.hidden-down {
        position: fixed;
        width: 100%;
        left: 0;
        z-index: 100000;
        top: 100vh;
        background-color: #f0f0f0;
        color: black;
        height: 90%;
        font-size: 11pt;
        font-weight: 300;
        overflow-y: scroll;
        padding-bottom: 100px;
        transition: .6s;
}

.full-overlay {
    position: absolute;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    transition: .6s ease-in-out;
    opacity: .8;
    background-color: rgba(228, 227, 227, 0.288);
}

.full-overlay i{
    position: fixed;
    top: 2.5%;
    font-size: 16pt;
    z-index: 99999999999999;
    right: 10px;
    opacity: 1;
    color: black;
    background: transparent;
    transition: .6s ease-in-out;


}

.text-box {
    padding-top: 10px;
    width: 90%;
    line-height: 1.6em;
    font-size: 11.5pt;
    font-weight: 400;
    margin: 0 auto;
    text-align: left;
    padding-bottom: 100px;
}

img.inside-text-box {
    padding-top: 5px;
    padding-bottom: 5px;
}

video.inside-text-box {
    width: 100%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

h1.crse-title {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 24pt;
    font-weight: 600;
    padding: 10px;
    padding-top: 20px;
    text-transform: uppercase;
    text-align: center;
    border-bottom: 1px solid #e72f60;
}

p.prelude {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: rgba(0, 0, 0, 0.788);
    padding: 10px;
    padding-bottom: 20px;
    font-style: italic;
}

h1.crse {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16pt;
    font-weight: 300;
    padding: 10px;
    text-transform: uppercase;
    padding-bottom: 20px;
}

p {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: rgba(0, 0, 0, 0.788);
    padding: 10px;
    padding-bottom: 20px;
}


p.closing {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: rgba(0, 0, 0, 0.788);
    padding: 10px;
    padding-bottom: 0;
    font-weight: 700;
}

h2.crse {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 14pt;
    text-transform: uppercase;
    font-weight: 300;
    color: black;
    padding: 10px;
}

.bloodborne p {
    color: red;
}

.course-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 990;
  left: 0;
  padding-top: 15px;
  padding-bottom: 15px;
  background: black;
  color: white;
  text-align: center;
  border-top: 1px solid #221f21;
}

.mobile-navbar-container {
  border-top: none;
}

.mobile-navbar-link {
  display: inline-block;
  position: relative;
  font-size: 8.5pt;
  font-weight: 280;
  color: #8a7a86;
  vertical-align: top;
  margin: 0 auto;
  width: 25%;
  opacity: 1;
  text-decoration: none;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.mobile-navbar-link-purp {
  display: inline-block;
  position: relative;
  font-size: 8.5pt;
  font-weight: 400;
  color: #e72f60;
  vertical-align: top;
  margin: 0 auto;
  width: 25%;
  opacity: 1;
  text-decoration: none;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}



.mobile-navbar-link-purp i {
  font-size: 15pt;
  font-weight: 400;
  padding-bottom: 4px;
}

.assignment-link {
  color: #e72f60;
}

.mobile-navbar-link i {
  font-size: 15pt;
  padding-bottom: 4px;
}

i {
  font-size: inherit;
}

.studio-entry-row-title {
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 10.5pt;
  font-weight: 400;
  padding: 0;
  padding-left: 5px;
  padding-bottom: 0px;
  background-color: transparent;
  
}

.assignment-submission {
  border-bottom: 1px solid rgba(255, 255, 255, 0.329);
}

.back-to-home {
  text-transform: uppercase;
  font-size: 10pt;
  font-weight: 550;
  padding-top: 20px;
  padding-left: 20px;
}

.pad-20-t {
  padding-top: 20px;
}
.wht-back {
  color: black;
  background-color: #f7f7f7;
  padding-bottom: 70px;
  overflow-y: scroll;

}

.white-back {
  background-color: white;
  width: 100%;
  padding: 0;
  margin: 0;
  margin-top: 10px;
}

.pad-10 {
  padding: 10px;
  padding-top: 20px;
}

.pad-20 {
  padding: 20px;
}

.font {
  font-size: 10pt;
  color: #939393;
  padding-top: 0px;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.pad-20 i {
  padding-top: 20px;
}

.welcome {
  position: absolute;
  top: 42%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  background-color: black;
}

.profile {
  position: absolute;
  z-index: 10000000000000000;
  top: 52px;
  right: 0;
  width: 30%;
  text-align: left;
  padding: 20px;
  padding-top: 30px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 12pt;
  background-color: white;
  color: black;
}

.profile-p {
  position: absolute;
  z-index: 10000000000000000;
  top: 52px;
  right: 0;
  width: 30%;
  text-align: left;
  padding: 20px;
  padding-top: 30px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 12pt;
  background-color: #f7f7f7;
  color: black;
}

.lower-case {
  text-transform: capitalize;
  padding-bottom: 10px;
  margin-bottom: 0;
}

i { font-size: inherit;}

h1 {
  font-weight: 300;
  font-size: 28px;
  text-align: left;
  padding: 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  text-transform: uppercase;
}

h1.assignments {
  font-weight: 300;
  padding-bottom: 0;
}

.course-logo-c {
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  height: 37px;
  padding: 20px;
  width: auto;
  position: absolute;

}

.no-pad {
  padding: 0;
  padding-top: 30px;
  padding-bottom: 20px;
}

.size-up {
  font-size: 42px;
}

@media (min-width: 800px) {
  .PMU-details {
    width: 100;
    margin: 0 auto;
  }

  .pmu-details-welcome {
    width: 70%;
    margin: 0 auto;
    text-align: center;
  }

  .welcome {
    width: 100%;
    margin: 0 auto;
    left: 50%;
    top: 23%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
  }

  .drop {
  }

  span.add {
    position: absolute;
    right: 30px;
    color: rgb(43, 43, 43);
    font-size: 9pt;
    background-color: #f7f7f7;
}

.p700 {
  width: 700px;
  margin: 0 auto;
}

.fifty {
  display: inline-block;
  margin: 0 auto;
  width: 25%;
  height: 200px;
  text-align: center;
  color: white;
}

.course-section {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  padding: 0px;
  width: 94%;
  height: 80%;
  border-radius: 20px;
  opacity: 1;
  background-color: rgb(0, 0, 0);
  box-shadow: rgba(88, 57, 102, 0.2) 0px 2px 8px 0px;

}

.course-section:hover {
  cursor: pointer;
}

.p600 {
  width: 600px;
  margin: 0 auto;
}

.purp {
  color: #e72f60;
  background-color: #e72f60;
}

}

.p {
  font-size: 10pt;
}
img.logo-lay2 {
    position: absolute;
    top: 40%; left: 50%; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%);
    z-index: -1;
    width: 40%;
    opacity: .8;
}

tr {
    
}

tbody {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.right-side {
    height: 90vh;
    overflow: hidden;
    overflow: scroll;
}


.each-artist {
    width: 160px;
    margin: 2px;
    display: inline-block;
    position: relative;
    vertical-align: top;
    text-align: center;
    background: #191919;
    background-color: #272727;
    background-color: #221f21;
    height: 1600px;
    margin-bottom: 200px;
    
}

.appointment-box3.box-90 {
    height: 45px;
    overflow: hidden;
    overflow: scroll;
    outline: .5px solid black;
}

.appointment-box.box-90 {
    height: 125px;
    overflow: scroll;
}

.appointment-box.box-120 {
    height: 180px;
    overflow: scroll;
}

.appointment-box.box-150 {
    height: 225px;
    overflow: scroll;
}


.appointment-box3.box-180 {
    height: 90px;
    overflow: scroll;
    outline: .5px solid black;
}

.appointment-box.box-180 {
    height: 270px;
    overflow: scroll;
}

.appointment-box3.box-210 {
    height: 105px;
    overflow: scroll;
    outline: .5px solid black;
}

.appointment-box.box-210 {
    height: 315px;
    overflow: scroll;
}

.appointment-box3.box-240 {
    height: 315px;
    overflow: scroll;
    outline: .5px solid black;
}

.appointment-box.box-240 {
    height: 360px;
    overflow: scroll;
}

.appointment-box3.box-360 {
    height: 180px;
    overflow: scroll;
    outline: .5px solid black;
}

.appointment-box.box-360 {
    height: 540px;
    overflow: scroll;
}



.artist-col-name {
    color: white;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    margin: 0 auto;
    padding: 12px;
    border-bottom: 2px solid #111011;;
}



img.logo-lay {
 position: absolute;
 top: 40%; left: 50%; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%);

 width: 40%;
 z-index: 9999999;
 background: linear-gradient(306deg, black, black, black, black, transparent, transparent, transparent);
 background-size: 200% 280%;

 -webkit-animation: WelcomePageGradient2 .8s ease infinite;
 animation: WelcomePageGradient2 .8s ease infinite;
 opacity: .8;

}

.appointment-box {
    margin: 10px;
    padding-top: 10px;
    padding-bottom: 30px;
    text-align: left;
    background-color:  #140512;
    background-color: #111011;
    padding: 10px;
    padding-bottom: 15px;
    white-space: normal;
    color: rgba(255, 255, 255, 0.951);
}

.appointment-box2 {
    margin: 10px;
    padding-top: 10px;
    padding-bottom: 20px;
    text-align: left;
    background-color:  #140512;
    background-color: #111011;
    padding: 10px;
    padding-bottom: 12px;
    white-space: normal;
    color: rgba(255, 255, 255, 0.951);
    overflow: scroll;
    position: relative;
    display: block;
    font-size: 8pt;

}

.appointment-box3 {
    margin: 10px;
    padding-top: 10px;
    padding-bottom: 20px;
    text-align: left;
    background-color:  #140512;
    background-color: #111011;
    padding: 10px;
    padding-bottom: 12px;
    white-space: normal;
    color: rgba(255, 255, 255, 0.951);
    overflow: scroll;
    position: relative;
    display: block;
    font-size: 8pt;
}

.appointment2-1000am {
    display: block;
    position: absolute;
    top: 10px;
}

.appointment-1000am {
    position: absolute;
    top: 45px;
}

.appointment2-1100am {
    position: absolute;
    top: 40px;
}

.appointment-1100am {
    position: absolute;
    top: 150px;
}

.appointment2-1130am {
    position: absolute;
    top: 55px;
}

.appointment-1130am {
    position: absolute;
    top: 200px;
}

.appointment-1200pm {
    position: absolute;
    top: 300px;
}

.appointment-1230pm {
    position: absolute;
    top: 300px;
}



.appointment2-100pm {
    position: absolute;
    top: 100px;
}


.appointment-100pm {
    position: absolute;
    top: 350px;
}

.appointment2-130pm {
    position: absolute;
    top: 115px;
}

.appointment-130pm {
    position: absolute;
    top: 480px;
}

.appointment2-200pm {
    position: absolute;
    top: 130px;
}

.appointment-200pm {
    position: absolute;
    top: 560px;
}

.appointment2-230pm {
    position: absolute;
    top: 145px;
}

.appointment-230pm {
    position: absolute;
    top: 675px;
}

.appointment2-300pm {
    position: absolute;
    top: 160px;
}

.appointment-300pm {
    position: absolute;
    top: 565px;
}

.appointment2-400pm {
    position: absolute;
    top: 190px;
}

.appointment-400pm {
    position: absolute;
    top: 655px;
}

.appointment-430pm {
    position: absolute;
    top: 780px;
}


.appointment2-500pm {
    position: absolute;
    top: 220px;
}

.appointment-500pm {
    position: absolute;
    top: 1005px;
}

.appointment-520pm {
    position: absolute;
    top: 1100px;
}



.appointment2-530pm {
    position: absolute;
    top: 235px;
}


.appointment-530pm {
    position: absolute;
    top: 815px;
}

.appointment-box3.lips {
    background:  #e72f60;
}

.appointment-box.lips {
    border: .5px solid #e72f60;
}

.appointment-box3.brows {
    background: #7b4397;
    
}

.appointment-box.brows {
    border: .5px solid #7b4397;
    
}

.appointment-box3.other {
    background:  #c5b206f5;
}

.appointment-box.other {
    border: .5px solid #c5b206f5;
}

.appointment-box.students {
    border: .5px solid #1c2ba2;
}

.appointment-box3.eyeliner {
    border: .5px solid white;
}

.appointment-box.eyeliner {
    border: .5px solid white;
}

.appointment-box3.package {
    border-bottom: .5px solid #e72f60;
    border-left: .5px solid #7b4397;
    border-right: .5px solid #e72f60;
    border-top: .5px solid #7b4397
}

.appointment-box.package {
    border-bottom: .5px solid #e72f60;
    border-left: .5px solid #7b4397;
    border-right: .5px solid #e72f60;
    border-top: .5px solid #7b4397
}



.appointment-box::after {
    width: 92%;
    background-clip: border-box;
    background: linear-gradient(306deg, #7b4397, #e72f60);
    background-size: 400% 400%;
    background-clip: border-box;
    color: transparent;
    -webkit-animation: WelcomePageGradient 4s ease infinite;
    animation: WelcomePageGradient 4s ease infinite;
}



.today-time {
    font-weight: 700;
    color: rgb(240, 240, 240);
    padding-top: 7px;
    margin-bottom: 5px;
    text-transform: uppercase;
    font-size: 9.5pt;
    display: inline-block;
}

.today-type {
    padding-top: 5px; padding-bottom: 5px;
    color: rgba(156, 156, 156, 0.89);
    text-transform: uppercase;
    font-size: 8.5pt;
    margin-top: 0px;
}

.today-type2 {
    padding-top: 5px; padding-bottom: 5px;
    color: rgba(156, 156, 156, 0.89);
    text-transform: uppercase;
    font-size: 8.5pt;
    margin-top: 0px;
}

.today-due {
    display: block;
    margin-top: 7px;
    margin-bottom: 0px;
    color: rgba(255, 255, 255, 0.89);
    text-transform: uppercase;
    font-size: 9pt;
    background-color: #272727;
    border: 1px solid transparent;
    padding: 5px;
    padding-left: 8px;
    padding-right: 8px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 20px;
}

.today-name {
    padding-top: 5px; padding-bottom: 5px;
    color: rgba(255, 255, 255, 0.89);
    text-transform: uppercase;
    font-size: 10pt;
}

.today-notes {
    color: rgb(102, 102, 102);
    font-style: italic;
    font-size: 8pt;
    padding-top: 5px; padding-bottom: 5px;
}

.scroll {
    overflow-y: scroll;
}

.day-box {
    text-align: left;
    display: inline-block;
    background-color: #313131;
    width: 30px;
    height: 30px;
    font-size: 9pt;
    text-align: left;
    padding: 10px;
    border-radius: 2px;
    margin: 2px;
}

.abs-s {
    position: absolute;
    top: 40%; left: 50%; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%);
    margin: 0 auto;
    text-align: center;
}

textarea.notes-input {
    margin-top: 10px;
    width: 300px;
    padding: 10px;
}

.abs-cal {
    position: absolute;
    margin: 0 auto;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    top: 30%;
    left: 50%; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%);
}

.main-container {
    position: relative;
    display: block;
    width: 100%;
    text-align: center;
}

.calendar-container {
    margin: 0 auto;
    width: 90%;
}



.calendar-day {

 
    width: 40px;
    height: 40px;
    border-radius: 50px;
    font-size: 9pt;
    padding: 5px;
    padding-bottom: 6px;
    padding-top: 4px;
    margin: 2px;
    text-align: center;

    transition: .3s;
    vertical-align: middle;
}

.dot.calendar-day {
    background-color: #221f21;
}

.no-dot td.calendar-day {
    background-color: transparent;
}

.calendar-day2 {
    background-color: #3d3d3d;
    background-color: #221f21;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    font-size: 9pt;
    padding: 5px;
    padding-bottom: 6px;
    padding-top: 4px;
    margin: 2px;
    text-align: center;
    transition: .3s;
    vertical-align: middle;
    position: relative;
}
.today.calendar-day2 {
    background-color: #7b4397;

}

.no-dot td.calendar-day2 {
    background-color: transparent;
}

.calendar-day3 {
    background-color: #3d3d3d;
    background-color: #221f21;
    width: 200px;
    height: 330px;
    border-radius: 3px;
    font-size: 9pt;
    padding: 5px;
    padding-bottom: 6px;
    padding-top: 4px;
    margin: 2px;
    text-align: left;
    transition: .3s;
    vertical-align: top;
    position: relative;
}

.calendar-table {
    margin: 0 auto;

}

td {
    text-align: center;
    padding: 3px;
    margin: 0 auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;

}

td:hover {
    cursor: pointer;
}

.calendar-month.today, .calendar-month-2.today {
    color: white;
}

td.today {
    color: black;
    transition: .2s;
    border: 2px solid white;
    border-radius: 50px;
    color: white;
    color: rgba(255, 255, 255, 0.89);
    padding: 4px;
    padding-left: 5px;
    -webkit-animation: WelcomePageGradient 4s ease infinite;
    animation: WelcomePageGradient 4s ease infinite;
}

td.today.dot {
    color: white;
    background-color: #7b4397;
}


td.today i {
    color: white;
    background-color: black;
    padding: 5px;
}

.abs-nav {
    position: absolute;
    top: 10px;
    right: 20px;
    z-index: 999999999999999999;
}

.abs-nav:hover {
    cursor: pointer;
}


.full-100 {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    
    padding-bottom: 100px;
 
 
}

.scheduled-appt-title {
    font-weight: 700;
    text-transform: uppercase;
    padding: 20px;
    padding-top: 10px;
    font-size: 12pt;
    margin: 0 auto;
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.scheduled-appt-title:hover {
    cursor: pointer;
}

.moving-gradient {
    background: linear-gradient(306deg, #7b4397, #e72f60);
    background-size: 400% 400%;
    -webkit-background-clip: text;
            background-clip: text;
    color: transparent;
    -webkit-animation: WelcomePageGradient 4s ease infinite;
    animation: WelcomePageGradient 4s ease infinite;
}

.bottom-half {
    position: relative;
    background-color: #221f21;
    padding-bottom: 10px;
    padding-top: 20px;
    min-height: inherit;
    min-height: 100vh;
}

.name-title {
    font-size: 14pt;
}

.year-list {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    text-align: center;
}

.inline {
    display: inline-block;
    padding: 10px;
    padding-bottom: 0;
    background: black;
}

.inline:hover {
    cursor: pointer;
}

td.calendar-month {
    display: block;
    padding: 10px;
    padding-top: 10px;
    padding-bottom: 10px;

    width: -webkit-fit-content;

    width: -moz-fit-content;

    width: fit-content;
    margin: 0 auto;
    position: relative;
    
    z-index: 9999999999999;
}

td.calendar-month4 {
    padding: 10px;
}

td.calendar-month:hover {
    display: block;
    padding: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    position: relative;
    background: rgb(39, 39, 39);
    z-index: 9999999999999;
}

td.calendar-month-2 {
    display: block;
    padding: 10px;
    padding-top: 7px;
    padding-bottom: 6px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
    margin: 0 auto;
} 
tr.cal-month-list {
    position: relative;
    display: block;
    z-index: 9999;
}

.abs-list {
    position: absolute;
    margin-top: 2%;
    left: 5%;
    background: black;
    background: black;

    z-index: 100;
}

.abs-list-2 {
    position: absolute;
    background: black;
    border: none;
    right: 5%;
    z-index: 100;
    margin-top: 2%;
}

.abs-list-3 {
    position: relative;
    background: black;
    padding-bottom: 0px;
    top: 60%;
    left: 50%;
    width: 400px;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 999;
    transition: .2s;

}

.medical-form-overall {
    color: red;
    font-size: 7pt;
    text-transform: uppercase;
    padding-top: 5px;
    padding-bottom: 5px;
}

.abs-list-4 {
    position: relative;
    z-index: 999;
    transition: .2s;
    left: 50%; -webkit-transform: translate(-50%); transform: translate(-50%);
  
}

.full-blur {
    position: fixed;
    top: 0; left: 0; right: 0; bottom: 0;
    width: 100%;
    height: 100vh;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.856);
    transition: .2s;
    -webkit-backdrop-filter: blur(7px);
            backdrop-filter: blur(7px);
}

.w-100 {
    width: 100%;
    text-align: left;
    padding-left: 0px;
    padding-top: 0;
}

tr.available {
    background-color: red;
}

.not-available {
    color: #7e7e7e;
    transition: .2s;
}

.available {
    background-color: #666666;
    transition: .2s;
}

.tudate {
    background-color: #e72f60;
    color: black;
    transition: .2s;
}

.gry {
    color: #999999;
}

.yellow {
    color: #e72f60;
}

.booking-link {
    padding: 12px;
    padding-left: 15px; padding-right: 15px;
    color: white;
    background-color: #e72f60;
    text-align: center;
    background: linear-gradient(306deg, #7b4397, #e72f60);
    background-size: 400% 400%;
    -webkit-animation: WelcomePageGradient 4s ease infinite;
    animation: WelcomePageGradient 4s ease infinite;
    text-transform: uppercase;
    font-size: 9pt;
    font-weight: 700;
    margin: 20px;
}

.booking-link:hover {
    cursor: pointer;
}

.booking-link-2 {
    padding: 12px;
    padding-left: 15px; padding-right: 15px;
    color: transparent;
    text-align: center;
    background: transparent;
    background-size: 400% 400%;
    -webkit-animation: WelcomePageGradient 4s ease infinite;
    animation: WelcomePageGradient 4s ease infinite;
    text-transform: uppercase;
    font-size: 9pt;
    font-weight: 700;
    margin: 20px;
}

.touch-up-link {
    display: inline-block;
    background-color: #313131;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px;
    padding-left: 10px; padding-right: 10px;
    border-radius: 20px;
    color: #bbbbbb;
    text-transform: uppercase;
    font-size: 8pt;
    font-weight: 500;
    margin-top: 8px;
    margin-bottom: 5px;
    margin-right: 8px;
}

.booking-page-full {
    position: fixed;
    top: 0px;
    padding-top: 60px;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    z-index: 999999999;
    background-color: rgba(0, 0, 0, 0.712);
    -webkit-backdrop-filter: blur(7px);
            backdrop-filter: blur(7px);
    z-index: 99999999999999999999999;

}

.booking-page-inside {
    position: relative;
    height: 90vh;
    overflow: scroll;
    padding-bottom: 100px;
}

.logo-layer-1 {
    position: absolute;
    top: 10%; left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 1;
    width: 62%;
    height: auto;
}

.logo-layer-2 {
    position: absolute;
    top: 10%; left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background: linear-gradient(306deg, black, black, black, black, transparent, transparent, transparent);
    background-size: 200% 180%;
  
    -webkit-animation: WelcomePageGradient2 6s ease ;
    animation: WelcomePageGradient2 6s ease ;
    z-index: 9;
    width: 62%;
    height: auto;
}

.logo-layer-2b {
    position: absolute;
    top: 50%; left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background: linear-gradient(306deg, black, black, black, black, transparent, transparent, transparent);
    background-size: 180% 180%;
  
    -webkit-animation: WelcomePageGradient 6s ease infinite;
    animation: WelcomePageGradient 6s ease infinite;
    z-index: 99999;
    width: 92%;
    height: auto;
}

.logo-flash {
    width: 50%;
    height: auto;

}

.btnlogin {
    position: absolute;
    top: 35.5%; -webkit-transform: translate(0, -50%); transform: translate(0, -50%);
    z-index: 99999999;
}

.front-layer {
    position: absolute;
    top: 40%;
    left: 50%;
    -webkit-transform: (-50%, -50%);
            transform: (-50%, -50%);
    z-index: 9999999999999999999;
}

.pnk-wrap {
    border: 2px solid #e72f60;
}

.login-box {
    width: 60%;
    margin: 0 auto;
}

td:hover {
    cursor: pointer;
}

button.refresh {
    color: white;
    position: absolute;
    left: 0;
    top: 4px;
    font-size: 14pt;
}

button.refresh:hover {
    cursor: pointer;
}

.submitted-by {
    display: inline-block;
    padding-top: 7px;
    padding-right: 7px;
    color: #7b7b7b;
    color: #63506c;
    font-size: 12px;
    padding-bottom: 10px;
 }

 .submitted-by.date {
    display: inline-block;
    font-style: italic;
    color: #4f4f4f;
    color: #63506c;
    color: #7b7b7b;
    font-size: 12px;
 }

 .submitted-by-body {
    padding-top: 7px;
 }
 

.task-list {
   background: black;
    height: 40vh;
    margin-top: -100px;
    padding-top: 0px;
    overflow: scroll;
    border-top: .5px solid white;
}

.fxd {
    position: sticky;
    top: 0;
    padding-top: 15px;
    z-index: 99;
    width: 90%;
    background: black;
    box-shadow: rgba(255, 255, 255, 0.247) 1.95px 1.95px 2.6px;
}

.inner-task-list {
    padding-bottom: 50px;
    width: 100%;
    white-space: normal;
}

.task-box {
    position: relative;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background: #221f21;
    margin-top: 10px;
    color: white;
    text-transform: none;
    font-size: 10pt;
    width: 90%;
    margin: 10px auto;
    padding: 10px;
}


.add-btn {
    width: 30px;
    height: 30px;
    background: #7b4397;
    border-radius: 50px;
    position: absolute;
    right: 10px;
    top: 10px;
    color: white;
    font-size: 14pt;
    text-align: center;
    vertical-align: middle;
    position: absolute;
}

.inner-add-btn {
    position: absolute;
    top: 48%; left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    text-align: center;
}

button:hover {
    cursor: pointer;
}

.add-task-box {
    position: absolute;
    width: 80%;
    top: 50%; left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.task-btn-row {
    text-align: right;
    margin-top: 10px;
    margin-bottom: 0px;
}

.task-btn {
    background: #121212;
    border-radius: 50px;
    color: white;
    padding: 8px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 0px;
}

.task-btn i {
    font-size: 11pt;
}

.task-btn:hover {
    cursor: pointer;
}

.searchbar-container {
   position: fixed;
   right: 80px;
   width: 400px;
   top: 6px;
}

.searchbar {
    position: absolute;
    width: 100%;
  
    background: #191919;
    border: 1px solid rgb(255, 255, 255);
}

.searchbar-results {
    position: relative;
    width: 400px;
    margin-top: 45px;
    background: #ffffff;
    padding: 7px;
    
}

.searchbar-result {
    margin: 2px;
    color: black;
    margin-bottom: 10px;
    padding: 5px;
}

.searchbar-result:hover {
    cursor: pointer;
}

.client-page-inside {
    padding-bottom: 100px;
}

.close-all {
    position: absolute;
    font-size: 14px;
    font-weight: 600;
    right: 30px;
}

.close-all:hover {
    cursor: pointer;
}

.client-page-name {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    padding-bottom: 10px;
    text-transform: uppercase;
}

.client-appt-type {
    font-size: 12px;
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 13px;
    color: rgba(255, 255, 255, 0.735);
}

.client-appt-type.date {
    font-weight: 600;
    margin-bottom: 5px;
    color: white;
    font-size: 12px;
}

.client-appt-type.time {
    font-weight: 500;
    margin-bottom: 0px;
    color: white;
    font-size: 12px;
}

.client-appt-type.notes {
    font-style: italic;
    margin-top: 8px;
}

.client-appt-type.canceled {
    color: white;
    background: rgb(207, 55, 55);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-top: 5px; padding-bottom: 5px;
    padding-left: 12px; padding-right: 12px;
    border-radius: 50px;
    margin-top: 8px;
}


.client-appt-box {
    margin-top: 10px;
    background: #2a2629;
    padding: 20px;
    white-space: normal;
}

a.inventory-link {
    color: white;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    font-size: 16px;
    font-weight: 500;
    right: 33%;
    text-transform: uppercase;
    display: inline-block;
    z-index: 99999999;
}

@media (max-width: 800px) {



    .booking-page-full {
        position: absolute;
        top: 0px;
        padding-top: 60px;
        width: 100%;
        min-height: 100vh;
        z-index: 999999999;
        background-color: rgba(0, 0, 0, 0.712);
        -webkit-backdrop-filter: blur(7px);
                backdrop-filter: blur(7px);
    }

    .login-box {
        width: 80%;
        margin: 0 auto;
    }

    .logo-flash {
        width: 100%;
        height: auto;
    }

        .logo-layer-1 {
            position: absolute;
            top: 10%; left: 50%;
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
            z-index: 1;
            width: 92%;
            height: auto;
        }
        
        .logo-layer-2 {
            position: absolute;
            top: 10%; left: 50%;
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
            background: linear-gradient(306deg, black, black, black, black, transparent, transparent, transparent);
            background-size: 200% 180%;
          
            -webkit-animation: WelcomePageGradient2 4s ease ;
            animation: WelcomePageGradient2 4s ease ;
            z-index: 9;
            width: 92%;
            height: auto;
        }

}

