.NavBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: black;
}

a:hover {
    cursor: pointer;
}

.NavBar button {
    height: 20px;
}

.Links1 {
    width: 100px;
    display: flex;
    justify-content: space-between;
}

.Links2 {
    width: 400px;
    display: flex;
    justify-content: space-between;
}

img.logo {
    position: absolute;
    top: 10px;
    left: 10px;
    height: 22px;
    width: auto;
    z-index: 100;
}

a {
    text-decoration: none;
}

.links {
    position: absolute;
    width: 100%;
    z-index: 1000;
    top: 12px;
    right: 12px;
    color: white;
    text-transform: uppercase;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
    font-size: 9pt;
    z-index: 999999999999999999;
}

a.login {
    position: absolute;
    color: white;
    right: 2px;
    top: 0px;
    font-weight: 550;
    z-index: 100;
}

.full-navbar {
    background-color: black;
    position: absolute;
    height: 50px;
    top: 0;
    width: 100%;
}
a.logout {
    color: black;
    position: absolute;
    right: 0;
    margin-top: 12px;
    z-index: 10000;
  }

  .back-btn {
      position: fixed;
      top: 5px;
      left: 5px;
      z-index: 99999999999;
      font-size: 35px;
      color: rgba(78, 78, 78, 0.199);
      color: #e4ccc6a9;
      color: rgba(128, 128, 128, 0.733);
  }

  .hamburger {
      position: absolute;
      right: 10px;
      top: 0px;
      color: white;
      height: 50px;
      width: 50px;
      font-size: 18pt;
  }

  .hamburger a {
      display: block;
      position: absolute;
      top: 14px;
      right: 12px;
      font-size: 12pt;
      font-weight: 550;
      text-transform: uppercase;
      z-index: 99;
      color: #554c52;
  }

  .course-nav-p .hamburger a {
      color: #3a0a17;
  }

  .course-nav {
      
      position: fixed;
      top: 0;
      width: 100vw;
      height: 52px;
      z-index: 9999;
      margin-bottom: 10px;
  }

  .course-nav-p {
    background: linear-gradient(to left, white, #c282e2cc, black) ;
    background: linear-gradient( to right, #e72f6088 , #7b439791 );
    position: fixed;
    top: 0;
    width: 100%;
    height: 52px;
    z-index: 9999999999999999999999999;
}


